<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox">
      <div class="flex justify-between">
        <!-- 左侧 -->
        <div class="left_box text-cut">
          <div class="menuList">
            <div
              class="menu_item"
              v-for="item in treeList"
              :key="item.typeCode"
            >
              <dl>
                <dt class="text-cut">{{ item.typeName }}</dt>
                <dd
                  class="text-cut"
                  v-for="machine in item.son"
                  :key="machine.typeCode"
                  @click="
                    gotoDetails(machine.typeCode, item.typeName, item.typeCode)
                  "
                >
                  {{ machine.typeName }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right_box bbox pt20 pb20 positionr flex1 plr15" >
          <div v-if="toolsList.length == 0" class="noMessage positiona">
            <img src="../../assets/images/kong.png" alt="" width="100" />
            <div class="f14 textc mt15">{{ $t("noData") }}</div>
          </div>
          <div class="list text666 " v-else style="font-size:12px;">
            <div v-for="tool in toolsList" :key="tool.typeCode">
              <div
                @click="toDetail(tool.typeCode, tool.typeName)"
                style="font-weight: 550"
              >
               <div class="typename"> {{ tool.typeName }}</div>
                <div class="upload ml10" v-if="tool.son.length == 0 &&tool.isDown =='1'"  @click="downloadFile(tool.typeCode, tool.typeName)" >
                  <img
                    src="../../assets/images/download.png"
                    alt=""
                    width="18"
                    height="18"
                    class="vm"
                  />
                  <span
                    class="text-blue pl5 vm"
                    >{{ $t("my.download") }}</span
                  >
                </div>
              </div>
              <div style="margin-bottom: 3%; margin-top: 3%">
                <div
                  v-for="son in tool.son"
                  :key="son.typeCode"
                  class="flex align-center"
                  style="margin-bottom: 11px; margin-left: 6%"
                  @click="toDetail(son.typeCode, son.typeName)"
                >
                  <span style="line-height: 20px">{{ son.typeName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
var tips1 = "提示";
var tips2 = "当前资料无培训试题，请联系管理管添加培训试卷！";
var tips3 = "当前资料无附件信息，请联系管理员添加！";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      treeList: [],
      allList: [],
      isAcitive: 11,
      toolsList: [],
      typeName: "",
    };
  },
  methods: {
    showMachineNode(typeCode) {
      this.isAcitive = typeCode;
    },
    loadScience() {
      var that = this;
      var query = {};
      query.memberCode = localStorage.getItem("memberCode");
      this.$http.post("scienceFile/queryTreeList", query).then(function (res) {
        if ((res.data.code = 200)) {
          that.treeList = res.data.data.treeList;
          that.allList = res.data.data.allList;
        }
      });
    },
    gotoDetails(typeCode, parentTypeName, parentTypeCode) {
      var that = this;
      that.typeName = parentTypeName;
      this.treeList.forEach((e) => {
        if (e.typeCode == parentTypeCode) {
          e.son.forEach((element) => {
            if (element.typeCode == typeCode) {
              that.toolsList = element.son;
            }
          });
        }
      });
    },
    toExam(typeCode) {
      var sendData = {};
      sendData.typeCode = typeCode;
      sendData.type = "3";
      var that = this;
      this.$http
        .post("exam/queryExamByTypeCode", sendData)
        .then(function (res) {
          if (200 == res.data.code && res.data.data != undefined) {
            var pushData = {};
            pushData.path = "/testDetail";
            var query = {};
            query.title = res.data.data.title;
            query.examCode = res.data.data.examCode;
            query.content = res.data.data.content;
            query.time = res.data.data.time;
            pushData.query = query;
            that.$router.push(pushData);
          } else {
            that.$notify.info({
              title: tips1,
              message: tips2,
              showClose: true,
            });
          }
        });
    },
    showScienceFile(typeCode, typeName) {
      var sendData = {};
      sendData.objectCode = typeCode;
      var that = this;
      this.$http.post("annexFile/queryFileList", sendData).then(function (res) {
        if (200 == res.data.code) {
          var file = res.data.data[0];
          if (file != undefined && file.fileUrl != undefined) {
            var url = file.fileUrl;
            var content = file.represent;
            that.$router.push({
              path: "/previewFile",
              query: {
                url: url,
                content: content,
                typeCode: typeCode,
                typeName: typeName,
                machineType: that.typeName,
              },
            });
          } else {
            that.$message(tips1 + ":" + tips3);
          }
        }
      });
    },
    toDetail(typeCode, typeName) {
      if ("学术测试" == this.from) {
        this.toExam(typeCode);
      } else {
        this.showScienceFile(typeCode, typeName);
      }
    },
     downloadFile (typeCode, typeName) {
      var sendData = {};
      sendData.objectCode = typeCode;
      var that = this;
      window.location="https://www.arkray-service.com.cn/api/annexFile/downFileList?objectCode="+typeCode
     },
  },
  created() {
    this.from = this.$route.query.from;
    this.loadScience();
  },
};
</script>

<style scoped>
/* // 左侧菜单列表 */
.left_box {
  width: 110px;
  height: 100%;
  background-color: #efefef;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.menuList .menu_item dt {
  background-color: #1f8bd0;
  color: #fff;
  padding: 4px 10px;
  cursor: pointer;
}
.menuList .menu_item dd {
  color: #818181;
  padding: 5px 10px;
  cursor: pointer;
  min-width: 100px;
}
.menuList .menu_item dd:hover {
  text-decoration: underline;
  background-color: #fff;
}
.active {
  color: #1f8bd0;
}
.list > div {
  padding: 4px 0;
  border-bottom: 1px solid #ededed;
}
.typename {
  font-size: 14px;

}
.upload {
  display: flex;
	align-items: center;
	justify-content: flex-end;
}
</style>